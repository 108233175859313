import React, { useEffect, useState } from 'react';
import '../../Styles/404/NotFound.css';

const NotFound = () => {
    const messages = [
        "Oops! The page you're looking for doesn't exist.",
        "We couldn't find what you were searching for.",
        "This is not the page you're looking for.",
        "Well, this is awkward. There's nothing here.",
        "Looks like you're lost in the void.",
        "The page you requested is out of orbit. Try another route!"
    ];

    const [randomMessage, setRandomMessage] = useState("");

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setRandomMessage(messages[randomIndex]);
    }, []);

    return (
        <div className="not-found">
            <h1>404</h1>
            <p>{randomMessage}</p>
        </div>
    );
};

export default NotFound;