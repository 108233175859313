import React from "react";
import "../Styles/About.css";
import Footer from "../components/Footer/Footer";

const About = () => {
  return (
    <>
      <div className="about-page">
        <div className="about-container">
          <div className="about-content">
            <h1>About Me</h1>
            <div className="about-details">
              <p>
                Hi! I'm <span class="highlight">Jules</span> and come from the{" "}
                <span class="highlight">Netherlands</span>. I'm currently in my
                third year as a{" "}
                <span class="highlight">Web Development student</span> at{" "}
                <span class="highlight">
                  HAN University of Applied Sciences
                </span>
                , where I am constantly expanding my knowledge and skills in{" "}
                <span class="highlight">Web Development</span>.
              </p>
              <p>
                Outside of coding, I'm also a{" "}
                <span class="highlight">referee for the KNVB</span> and enjoy
                reading, listening to music, and spending time outdoors, whether
                it's walking with my <span class="highlight">dog</span> or
                riding my <span class="highlight">motorcycle</span>. 
                {/* I'm also
                passionate about pursuing a career as a{" "}
                <span class="highlight">police officer</span>, {""}
                where I can help people and make a difference in my community. */}
              </p>
            </div>
          </div>
          <div className="about-image">
            <img src="/images/pfp.jpg" alt="Jules - Web Developer" />
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="wave wave-bottom"
          preserveAspectRatio="none"
        >
          <path
            fill="#214F4B" /* Background color of the previous section */
            d="M0,224L48,218.7C96,213,192,203,288,192C384,181,480,171,576,165.3C672,160,768,160,864,181.3C960,203,1056,245,1152,240C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>

        {/* Experiences and Education */}
        <div className="extra-container">
          <div className="about-content">
            <h2>Experience</h2>
            <div className="about-details">
              <h3>Stichting BKR | Software Engineer</h3>
              <p>
                At <span className="highlight">Stichting BKR</span>, I worked in
                the team 'Portalen' where I helped develop their new 'Zakelijk
                Portaal'. In this period, I worked with technologies like{" "}
                <span className="highlight">C#</span>,{" "}
                <span className="highlight">.NET 8</span>,{" "}
                <span className="highlight">Azure DevOps</span>, and{" "}
                <span className="highlight">Blazor</span>. I also optimized the
                loading time by decreasing the amount of time it took to compile the <span className="highlight">SASS files</span> when building the project.
              </p>
              <h3>End of File Studio | Concept Owner</h3>
              <p>
                As the Concept Owner at{" "}
                <span className="highlight">End of File Studio</span>, I was/ am
                responsible for maintaining the vision of the game and ensuring
                it was realized in the final product. I collaborated with
                programmers, artists, and designers to bring the game to life.
              </p>
            </div>
          </div>
          <div className="about-content">
            <h2>Education</h2>
            <div className="about-details">
              <h3>HAN University of Applied Sciences | HBO-ICT</h3>
              <p>
                I'm currently studying at the{" "}
                <span className="highlight">
                  HAN University of Applied Sciences
                </span>{" "}
                where I'm pursuing a Bachelor's degree in HBO-ICT. I'm
                specializing in Web Development and I'm expected to graduate in
                February of 2026.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
