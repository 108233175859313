import React from "react";
import "../../Styles/Main/Hero.css";

const Hero = () => {
  return (
    <>
      <div className="about-page">
        <div className="about-container">
          <div className="about-content">
            <h1>Welcome to my portfolio</h1>
            <div className="about-details">
              <p>
                I'm <span className="highlight">Jules</span>, a{" "}
                <span className="highlight">21-year-old</span> student in{" "}
                <span className="highlight">Web Development</span>. Currently
                studying at{" "}
                <span className="highlight">
                  HAN University of Applied Sciences
                </span>
                , you can see some of my work on this website. Feel free to
                contact me if you have any questions or want to{" "}
                <span className="highlight">work together</span>, you can reach
                me at{" "}
                <span className="highlight">
                  <a href="mailto:julesk1702@gmail.com">julesk1702@gmail.com</a>
                </span>
                .
              </p>
            </div>
          </div>
          <div className="about-image">
            <img src="/images/homepfp.jpeg" alt="Jules - Web Developer" />
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="wave wave-bottom"
          preserveAspectRatio="none"
        >
          <path
            fill="#214F4B" /* Background color of the previous section */
            d="M0,224L48,218.7C96,213,192,203,288,192C384,181,480,171,576,165.3C672,160,768,160,864,181.3C960,203,1056,245,1152,240C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </>
  );
};

export default Hero;
