import React from "react";
import "../../Styles/Main/Projects.css";
import { Link } from "react-router-dom";

const projects = [
  {
    id: "quintor",
    title: "Event App for Quintor",
    description:
      "For our OOSE semester final project, my team and I developed a mobile-first web application for Quintor, enabling employees to create events, propose ideas, and sign up for events. Focused on accessibility and usability, the app simplifies event organization, lowering barriers for employees to take initiative.",
    image: "/images/quintor.jpeg",
  },
  {
    id: "zwembaddreumel",
    title: "Zwembad De Zeven Morgen App",
    description:
      "A React Native mobile app for Zwembad De Zeven Morgen, featuring real-time opening times, notifications, account creation, and subscription scanning. Set to launch on Android next season, the app enhances visitor convenience and engagement.",
    image: "/images/zwembad.png",
  },
  {
    id: "sektar",
    title: "Sektar 13: Echoes of Fear - Video Game",
    description:
      "A psychological horror game developed in Unity, Sektar 13: Echoes of Fear immerses players in a chilling space station full of suspense and terror. As the Concept Owner, I was responsible for protecting the game's vision and ensuring it was realized in the final product.",
    image: "/images/eof.png",
  },
];

const Projects = () => {
  return (
    <section id="projects" className="projects">
      <h2>My Projects</h2>
      {projects.map((project, index) => (
        <div
          className={`project ${
            index % 2 === 0 ? "align-left" : "align-right"
          }`}
          key={index}
        >
          <div className="project-content">
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <Link to={`/projects/${project.id}`} className="btn">
              View Project
            </Link>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Projects;
