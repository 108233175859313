import React from "react";
import Hero from "../components/Main/Hero";
import Projects from "../components/Main/Projects";
import Footer from "../components/Footer/Footer";
import "../Styles/Home.css";

const Home = () => {
  return (
    <div className="main-container">
      <div className="content">
        <Hero />
        <Projects />
      </div>
    </div>
  );
};

export default Home;
