import React from "react";
import "../Styles/ProjectsOverview.css";
import { Link } from "react-router-dom";

const projects = [
  {
    id: "quintor",
    title: "Event App for Quintor",
    description:
      "For our OOSE semester final project, my team and I developed a mobile-first web application for Quintor, enabling employees to create events, propose ideas, and sign up for events. Focused on accessibility and usability, the app simplifies event organization, lowering barriers for employees to take initiative.",
    image: "/images/quintor.jpeg",
  },
  {
    id: "zwembaddreumel",
    title: "Zwembad De Zeven Morgen App",
    description:
      "A React Native mobile app for Zwembad De Zeven Morgen, featuring real-time opening times, notifications, account creation, and subscription scanning. Set to launch on Android next season, the app enhances visitor convenience and engagement.",
    image: "/images/zwembad.png",
  },
  {
    id: "sektar",
    title: "Sektar 13: Echoes of Fear - Video Game",
    description:
      "A psychological horror game developed in Unity, Sektar 13: Echoes of Fear immerses players in a chilling space station full of suspense and terror. As the Concept Owner, I was responsible for protecting the game's vision and ensuring it was realized in the final product.",
    image: "/images/eof.png",
  },
];

const ProjectsOverview = () => {
  return (
    <>
      <div className="about-page">
        <div className="about-container">
          <div className="about-content projects-overview">
            <h1>Some of my Projects</h1>
            <div className="about-details">
              <p>
                Welcome to my projects page! Here, you'll find a selection of my
                work showcasing my{" "}
                <span className="highlight">passion for developing</span>.
                Whether it's building a website to{" "}
                make event planning easier,
                creating a mobile app to{" "}
                enhance visitor experiences{" "}
                at a local swimming pool, or developing an{" "}
                immersive video game that
                keeps players on edge, I'm always excited to take on{" "}
                <span className="highlight">new challenges</span> and bring
                ideas to life.
              </p>
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="wave wave-bottom"
          preserveAspectRatio="none"
        >
          <path
            fill="#214F4B" /* Background color of the previous section */
            d="M0,224L48,218.7C96,213,192,203,288,192C384,181,480,171,576,165.3C672,160,768,160,864,181.3C960,203,1056,245,1152,240C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
      <section id="projects" className="projects">
        <h2>My Projects</h2>
        {projects.map((project, index) => (
          <div
            className={`project ${
              index % 2 === 0 ? "align-left" : "align-right"
            }`}
            key={index}
          >
            <div className="project-content">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <Link to={`/projects/${project.id}`} className="btn">
                View Project
              </Link>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default ProjectsOverview;
