import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home.js";
import About from "./Pages/About.js";
import "./App.css";
import Navbar from "./components/Navigation/Navbar.js";
import NotFound from "./Pages/404/NotFound.js";
import { useEffect } from "react";
import ProjectDetails from "./Pages/ProjectDetails.js";
import Footer from "./components/Footer/Footer.js";
import ProjectsOverview from "./Pages/ProjectsOverview.js";

function App() {
  useEffect(() => {
    document.title = "Portfolio | Jules Koster";
  }, []);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<ProjectsOverview />} />
        <Route path="/about" element={<About />} />
        {/* Project Pages */}
        <Route path="/projects/:projectId" element={<ProjectDetails />} />     
        {/* 404 Page */}
        <Route path="/oops" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/oops" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
