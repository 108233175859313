import React from "react";
import { useParams } from "react-router-dom";
import "../Styles/ProjectDetails.css";

// Mock project data
const projectData = {
  quintor: {
    title: "Event App for Quintor",
    description:
      "As part of our OOSE semester final project, I collaborated with my teammates to develop a web application for Quintor. Designed primarily for mobile users, the application allows employees to create events, propose ideas, and sign up for events, simplifying and encouraging employee initiative.",
    goal: "The goal was to make event creation more accessible and user-friendly, fostering a culture of collaboration within the company.",
    methodology:
      "Our team followed the SCRUM methodology, and I took on the role of Scrum Master, stepping outside my comfort zone and growing significantly through the process.",
    result:
      "In just eight weeks, we delivered a product that both our client and instructors appreciated. The client was especially pleased with its functionality and design, and the final project was graded a 7.",

    image: "/images/quintor.jpeg",
    link: "https://github.com/julesk1702/QuintorEventplanner",
    category: "Web Development",
    languages: ["JavaScript", "HTML", "CSS"],
    frameworks: ["React", "Node.js"],
  },
  zwembaddreumel: {
    title: "Zwembad De Zeven Morgen App",
    description:
      "The Zwembad De Zeven Morgen app is my first experience in mobile app development, built using React Native. This project focuses on improving visitor convenience by providing real-time opening hours, notifications about schedule changes, and event updates.",
    goal: "To improve the experience of visitors by offering a convenient and accessible mobile platform that simplifies access to pool schedules, events, and subscriptions.",
    methodology:
      "The app was developed with a focus on user-centered design, ensuring it is intuitive for all visitors. React Native was used to ensure cross-platform compatibility.",
    result:
      "Set to release on Android next year for the new swimming season, this app represents a significant milestone in my development journey.",
    image: "/images/zwembad.png",
    link: "https://wwww.zwembaddreumel.nl",
    category: "Mobile App Development",
    languages: ["JavaScript"],
    frameworks: ["React Native"],
  },
  sektar: {
    title: "Sektar 13: Echoes of Fear - Video Game",
    description:
      "A psychological horror game developed in Unity, Sektar 13: Echoes of Fear immerses players in a chilling space station full of suspense and terror. The player explores a decaying, abandoned station, uncovering its mysteries while managing their fear levels, which dynamically alter the gameplay and story.",
    goal: "To create an engaging and immersive psychological horror experience where player fear dynamically influences the narrative and game mechanics.",
    methodology:
      "Developed as part of a team project using an iterative approach. I acted as the Concept Owner, ensuring the game’s vision was maintained while collaborating with programmers, artists, and designers to bring it to life.",
    result:
      "The game successfully delivered a unique mechanic-driven horror experience, showcasing innovation in storytelling and player interaction.",
    image: "/images/eof.png",
    link: "#",
    category: "Game Development",
    languages: ["C#"],
    frameworks: ["Unity"],
    features: [
      "Dynamic Fear System influencing gameplay and story",
      "Terminal puzzles and exploration",
      "Decaying space station environment",
      "Breath control minigame",
    ],
  },
};

const ProjectDetails = () => {
  const { projectId } = useParams(); // Access the dynamic part of the URL

  console.log(projectId);
  // Get project details based on the projectId
  const project = projectData[projectId];

  // Handle invalid project IDs redirect to /404
  if (!project) {
    window.location.href = "/404";
  }

  return (
    <>
      <div className="about-page">
        <div className="about-container">
          <div className="about-content">
            <h1>{project.title}</h1>
            <div className="about-details">
              <p>{project.description}</p>
            </div>
          </div>
          <div className="about-image">
            <img src={project.image} alt={project.title} />
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="wave wave-bottom"
          preserveAspectRatio="none"
        >
          <path
            fill="#214F4B" /* Background color of the previous section */
            d="M0,224L48,218.7C96,213,192,203,288,192C384,181,480,171,576,165.3C672,160,768,160,864,181.3C960,203,1056,245,1152,240C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>

      {/* Tech Stack Section */}
      <div className="tech-stack">
        <div className="stack-item">
          <h3>Category</h3>
          <p>{project.category}</p>
        </div>
        <div className="stack-item">
          <h3>Languages</h3>
          <p>{project.languages.join(", ")}</p>
        </div>
        <div className="stack-item">
          <h3>Frameworks</h3>
          <p>{project.frameworks.join(", ")}</p>
        </div>
      </div>

      {/* Project Details Section */}
      <div className="project-details">
        <h2>Project Details</h2>
        <div className="details-item">
          <h3>Goal</h3>
          <p>{project.goal}</p>
        </div>
        <div className="details-item">
          <h3>Methodology</h3>
          <p>{project.methodology}</p>
        </div>
        <div className="details-item">
          <h3>Result</h3>
          <p>{project.result}</p>
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
