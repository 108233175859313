import React from "react";
import "../../Styles/Footer/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-content">
        <div className="footer-information">
          <div className="footer-section contact-info">
            <h3>Contact</h3>
            <a href="mailto:julesk1702@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </a>
          </div>
          <div className="footer-section">
            <h3>Follow Me</h3>
            <div className="social-media">
              <a
                href="https://www.linkedin.com/in/jules-koster/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a
                href="https://github.com/julesk1702"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-section copyright">
          <p>&copy; 2024 Jules Koster. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
